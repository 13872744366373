/* Spinner.css */

.modal-overlay {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.modal-overlay2{
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content:center;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    /* Allow the modal to take up most of the screen width on small devices */
    width: 100%;
    margin: 1rem;
    /* Adds some spacing around the modal on small screens */
    position: relative;
    bottom: -200px;
    left: 5px;
}
.modal-content2{
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.375rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    /* Allow the modal to take up most of the screen width on small devices */
    width: 100%;
    margin: 1rem;
}


@media (min-width: 576px) {
    .modal-content {
        max-width: 25rem;
        /* For small devices, set a moderate max-width */
    }
    .modal-content2 {
        max-width: 25rem;
        /* For small devices, set a moderate max-width */
    }
    
}

@media (min-width: 768px) {
    .modal-content {
        max-width: 30rem;
        /* For medium devices, increase the max-width */
    }
    .modal-content2 {
        max-width: 30rem;
        /* For medium devices, increase the max-width */
    }
}

@media (min-width: 992px) {
    .modal-content {
        max-width: 35rem;
        /* For large devices, increase the max-width */
    }
    .modal-content2 {
        max-width: 35rem;
        /* For large devices, increase the max-width */
    }
}

@media (min-width: 1200px) {
    .modal-content {
        max-width: 40rem;
        /* For extra-large devices, use the widest max-width */
    }
    .modal-content2 {
        max-width: 65rem;
    }
}

.modal-header {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.modal-description {
    margin-bottom: 1.5rem;
}

.modal-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 0.5rem;
    width: 180px;
    transition: background-color 0.3s;
}

.accept-button {
    background-color: #1526F2;
    color: #ffffff;
}

.accept-button:hover {
    background-color: #16a34a;
}

.reject-button {
    background-color: #1526F2;
}

.reject-button:hover {
    background-color: #dc2626;
}

.optional-button {
    background-color: white;
    border: 1px solid black;
    color: #000;
}

.optional-button:hover {
    background-color: #ca8a04;
    color: white;

}

/* ToggleButton.css */

.toggle-button {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    border: none;
    /* Ensure no border to avoid unexpected layout issues */
    outline: none;
    /* Remove default focus outline */
    overflow: hidden;
    /* Ensure the slider stays within the button */
}

.toggle-button .slider {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 34px;
    transition: transform 0.3s ease;
}

.toggle-button.on .slider {
    transform: translateX(26px);
    /* Adjust based on the width of the slider */
}

.toggle-button.off .slider {
    transform: translateX(-2px);
    /* Slide to the left */
}


.modal-button2 {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 0.5rem;
    width: 250px;
    transition: background-color 0.3s;
}
.accept-button2 {
    background-color: #96BE9C;
    color: #000000;
    font-weight: 600;

}
.modal-button2 :hover{
background-color: #78987C;
}
.modal-button3 {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: rgb(255, 255, 255);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 0.5rem;
    width: 250px;
    transition: background-color 0.3s;
}
.reject-button3 {
    background-color: #DDDDDD;
    color: #000000;
    font-weight: 600;
}