.text-editor h1 {
	font-size: 2em;
}

.text-editor h2 {
	font-size: 1.5em;
}

.text-editor h3 {
	font-size: 1.17em;
}
